import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { get } from "../../api";

export const useStudentAssessmentResultsDrawerQuery = (paths) => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-assessment-results", studentId],
    queryFn: async ({ signal }) => {
      const response = await get(paths.assessmentResults, { signal });

      return response.data;
    },
  });
};

export const useStudentHighFrequencyWordsAssessmentResultsDrawerQuery = (hfwAssessmentId) => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-hfw-assessment-results-drawer", studentId, hfwAssessmentId],
    queryFn: async ({ signal }) => {
      const response = await get(
        `/students/${studentId}/student_high_frequency_word_assessments/${hfwAssessmentId}`,
        {
          signal,
        }
      );

      return response.data;
    },
  });
};
